.selfie-portrait {
    width: 100%;
    max-height: 400px;
    border: 0.5px solid rgba(29, 30, 35, 0.1);
    border-radius: 4px;
    object-fit: cover;
}

.document-img {
    max-height: 400px;
    border: 0.5px solid rgba(29, 30, 35, 0.1);
    border-radius: 4px;
}

.img-empty {
    height: 275px;
    display: flex;
    background-color: $gray-1;
    border: 0.5px solid rgba(29, 30, 35, 0.1);
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    color: $gray-4;
    align-items: center;
    gap: 16px;

    i {
        font-size: 128px;
    }

    p {
        @extend .font-weight-bold;
        @extend .font-size-s;
    }
}