@import './color';

.divider-lightblue {
    border-bottom: 1px solid $connect-lightblue;
    margin: 16px 0;
}

.divider-lightblue-medium-margin {
    border-bottom: 1px solid $connect-lightblue;
    margin: 32px 0;
}

.divider-lightblue-vertical {
    border-right:  1px solid $connect-lightblue;
    margin: 0 16px;
}

.divider-lightblue-vertical-medium-margin {
    border-right:  1px solid $connect-lightblue;
    margin: 0 32px;
}