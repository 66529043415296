$card-padding: 24px;

.process-card {
    border-radius: 8px;
    border: 1px solid $gray-2;
    background-color: $white;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
}

.process-card-head {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding: $card-padding;
    background-color: $connect-lightblue;
}

.process-head-collapsed {
    border-radius: inherit;
}

.process-card-body {
    padding: $card-padding;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.process-card-navigation {
    padding: $card-padding;
    display: flex;
    place-content: center flex-start;
    align-items: center;
    gap: 32px;

    > div {
        flex: 1;
    }
}

.process-card-footer {
    padding: $card-padding
}

.process-caption {
    @extend .font-size-xs;
    @extend .text-gray-6;
    margin-bottom: 8px;
}