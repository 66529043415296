@import '~sass/breakpoints';
@import '~sass/color';

app-dialog {
    height: 100%;
}

app-dialog.dialog-large-header-title .dialog-header-title {
    font-size: 32px;
}

.dialog-header {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.dialog-container {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px;
    top: 0;
    left: 0;

    h3.dialog-header-title {
        font-size: 18px;
        padding: 20px 0px 20px 20px;
    }

    .dialog-content {
        height: calc(100% - 160px);

        [body] {
            min-height: 100%;
        }

        flex: 1 1;
        padding: 0 2px;
        overflow: auto;
        padding: 10px 20px 0px 20px;
    }

    .dialog-close {
        margin-right: 5px;
    }

    [footer-actions] {
        background-color: $gray-0;
        padding: 24px 32px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 12px;

        > button {
            width: 100%;
    
            @include media-breakpoint('gt-xs') {
                width: auto;
            }
        }

        &.padding-top-8 {
            padding-top: 8px;
        }
    }

    &:not(.right) {
        overflow: hidden;
        border-radius: 15px;
        .dialog-header {
            position: relative;
            background-color: transparent !important;
            padding: 16px 24px;
            .dialog-header-title {
                color: $primary !important;
                font-size: 24px;
                font-weight: 700;
                padding: 0;
                background-color: transparent !important;
                height: auto !important;
            }
            button.dialog-close {
                position: absolute;
                right: 30px;
                top: 30px;
                margin: 0;
                width: 30px;
                height: 30px;
                line-height: 20px;
                mat-icon {
                    margin: 0;
                }
            }
        }
        .dialog-content {
            padding: 16px 32px !important;
        }
        [footer-actions] {
            padding: 24px 32px;
        }
    }
    &.right {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: auto;
        z-index: 999;
        width: 1200px;
        max-width: 100vw;
        height: 100%;
        .dialog-header {
            border-radius: 0;
            height: auto;
            padding: 30px;
            margin-bottom: 20px;
            background: none !important;
            h3.dialog-header-title {
                font-size: 24px;
                font-weight: 700;
                padding: 0;
                color: $primary !important;
            }
            .dialog-close {
                text-align: center;
                padding: 0 !important;
                width: 30px;
                height: 30px;
                line-height: 20px;
                margin: 0;
                mat-icon {
                    margin: 0;
                }
            }
        }
        .dialog-content {
            position: static;
            height: calc(100% - 271px);
            flex: none !important;
            border: 0;
            border-radius: 0;
            padding: 0 30px;
            overflow-y: scroll;

            section {
                border-bottom: 1px solid $grey-250;
                margin-bottom: 20px;
                padding-bottom: 20px;
                &:last-of-type {
                    border-bottom: 0;
                    margin-bottom: 0;
                }
                h3 {
                    margin-bottom: 20px;
                }
            }
            
            .mat-form-field-date-picker button {
                position: relative;
            }
        }
        .button-container {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: transparent!important;
            padding: 30px;
            [footer-actions] {
                padding: 0;
                flex-direction: column;
            }
        }
    }
}

body .mat-dialog-container {
    border-radius: 0;
    box-shadow: none;
    background: none;
}

