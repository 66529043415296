@import './color';

progress {
    border-radius: 4px; 
    height: 8px;
    width: 100%;
}

progress::-webkit-progress-bar {
    background-color: $connect-lightblue;
    border-radius: 4px;
}

progress::-webkit-progress-value {
    border-radius: 4px;
}

progress::-webkit-progress-value { 
    background-color: $connect-primary
}

progress[value~="100"]::-webkit-progress-value  {
    background-color: $connect-positive;
} 