.lock-switch {
    width: fit-content;
    cursor: pointer;
    margin-bottom: 16px;
}

.lock-switch-wrapper {
    display: inline-block;
    background-color: $gray-1;
    margin-right: 16px;
    border-radius: 40px;
}

.lock-wrapper {
    display: inline-block;
    border-radius: inherit;

    i {
        padding: 18px;
        color: $gray-3;
    }

    &.active {
        background-color: $connect-primary;

        i {
            color: $white;
        }
    }
}