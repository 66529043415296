@import './font';

.active-check-navigation-wrapper {
    ul {
        list-style-type: none;
        padding-left: 0;
    }
    
    a, button { 
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        color: $gray-8;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-decoration: none;
    }

    a::after, button::after {
        content: "";
        float: left;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        margin-top: 16px;
        visibility: hidden;
        transform: scaleX(0);
        transition: all .2s ease-in-out;
    }

    a:hover::after, button:hover::after {
        visibility: visible;
        transform: scaleX(1);
    }

    button {
        padding: 0;
        border: 0;
        background-color: transparent;
        font-family: $font;
    }

    a:not(:first-of-type), button:not(:first-of-type) {
        padding: 0 10px;
    }

    a.active::after, a:hover::after, button.active::after, button:hover::after {
        transform: scaleX(1);
        visibility: visible;
        background-color: $connect-primary;
    }
}

.active-check-result-wrapper {
    padding: 8px 24px 24px 24px;
}

.active-check-tiny-header {
    @extend .text-gray-6;
    @extend .font-size-xs;
    @extend .font-weight-bold;
    margin-bottom: 8px;
}

.active-check-content-wrapper {
    padding: 24px; 
}