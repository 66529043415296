@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900");
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900");
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900");
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900");
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900");
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900");
@import url("https://fonts.googleapis.com/css?family=Poppins");
.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

body {
  font-family: "Inter", sans-serif;
}

.font-headline {
  font-size: 4em;
  font-weight: 900;
  margin-block: 0.375em;
  line-height: 78px;
}

.font-heading-1, h1 {
  color: #323343;
  font-size: 2.625em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-2, h2 {
  color: #323343;
  font-size: 1.875em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-3, h3 {
  color: #323343;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 32px;
}

.font-heading-4, h4 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
  margin-block: 0.75em;
}

.font-heading-5, h5 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-6, h6 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-7, h7 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-8, h8 {
  color: #323343;
  font-size: 1em;
  font-weight: 700;
}

.text-white {
  color: #FFFFFF;
}

.text-gray-1 {
  color: #F6F6F6;
}

.text-gray-3 {
  color: #D4D4D4;
}

.text-gray-4 {
  color: #C4C4C4;
}

.text-gray-5 {
  color: #959595;
}

.text-gray-6, .active-check-tiny-header, .process-caption, .list-head-title {
  color: #757575;
}

.text-gray-8 {
  color: #333333;
}

.text-connect-primary {
  color: #323343;
}

.text-connect-accent {
  color: #F1A948;
}

.text-positive {
  color: #15BB8F;
}

.text-invalid {
  color: #E05050;
}

.text-alert {
  color: #EFAF41;
}

.text-italic {
  color: #525252;
  font-style: italic;
}

.font-size-xs, .active-check-tiny-header, .process-caption {
  font-size: 0.75em;
}

.font-size-s, .list-head-title, .img-empty p {
  font-size: 0.875em;
}

.font-size-regular {
  font-size: 1em;
}

.font-size-l {
  font-size: 1.125em;
}

.font-size-xl {
  font-size: 1.5em;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold, .active-check-tiny-header, .img-empty p {
  font-weight: 700 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.icon-size-s {
  font-size: 14px;
}

.icon-size-m {
  font-size: 24px;
}

.icon-size-regular {
  font-size: 16px;
}

.icon-size-l {
  font-size: 32px;
}

.icon-size-xl {
  font-size: 48px;
}

.icon-size-xxl {
  font-size: 56px;
}

.icon-size-xxxl {
  font-size: 72px;
}

.icon-size-huge {
  font-size: 120px;
}

.clamp-overflow-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

* {
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  text-rendering: auto;
}

html {
  height: 100%;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 1em;
  height: 100%;
  margin: 0;
  background-color: #FAFAFA;
}
body app-root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
}

h3 {
  font-weight: 700;
}

h5 {
  font-size: 14px;
  font-weight: 700;
}

p {
  margin-bottom: 20px;
}

h1.border,
h2.border,
h3.border,
h4.border,
a {
  text-decoration: none;
  cursor: pointer;
}

section {
  margin-bottom: 30px;
}

.link {
  cursor: pointer;
  -webkit-transition: all 0.2s;
  /* Safari prior 6.1 */
  transition: all 0.2s ease-out;
  display: flex;
  align-items: center;
}

a.link:hover {
  text-decoration: underline;
}

input[type=text],
input[type=phone],
input[type=number],
input[type=email],
input[type=tel],
input[type=date],
input[type=password],
input[type=file],
textarea {
  width: 100%;
  border: 0;
  padding: 0 10px 0 0;
  background: transparent;
  height: auto;
  line-height: normal;
  box-shadow: none;
  -webkit-appearance: none;
}

textarea {
  display: block;
  outline: none;
  max-height: 100px;
  height: auto;
  line-height: normal;
  padding-top: 10px;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

*[hidden], .hidden, .hide {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #FAFAFA;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 7px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

@media screen and (max-width: 599px) {
  .inset-container {
    margin: 0 5px;
  }
}
@media screen and (min-width: 600px) {
  .inset-container {
    margin: 0 10px;
  }
}
@media screen and (min-width: 960px) {
  .inset-container {
    margin: 0 25px;
  }
}
@media screen and (min-width: 1280px) {
  .inset-container {
    margin: 0 50px;
  }
}
@media screen and (min-width: 1920px) {
  .inset-container {
    margin: 0 75px;
  }
}

.position-relative {
  position: relative;
}

.round-corners {
  border-radius: 6px;
}

.border-radius-top-5 {
  border-radius: 5px 5px 0 0;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-30 {
  border-radius: 30px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.padding-bottom-none {
  padding-bottom: 0 !important;
}

.padding-none {
  padding: 0 !important;
}

.padding-4 {
  padding: 4px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-8 {
  padding: 8px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-16 {
  padding: 16px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-24 {
  padding: 24px !important;
}

.padding-32 {
  padding: 32px !important;
}

.padding-48 {
  padding: 48px !important;
}

.padding-right-10 {
  padding-right: 10px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.overflow-scroll, .table-wrapper, .table-wrapper-compact {
  overflow: auto;
}

.overflow-scroll-x {
  overflow-x: auto;
}

.overflow-scroll-y {
  overflow: visible;
  max-width: 100%;
}

.overflow-scroll-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.text.headline {
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
}
.text.sub-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.text.body {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.text.label {
  font-size: 16px;
  font-weight: 600;
}
.text.label.secondary {
  font-size: 14px;
  font-weight: 500;
}
.text.italic {
  font-style: italic;
}
.text.align-left {
  text-align: left;
}
.text.align-center {
  text-align: center;
}
.text.align-right {
  text-align: right;
}
.text.font-large-headline {
  font-size: 3.5rem;
  font-weight: 700;
}
.text.font-body-xxlarge {
  font-size: 1.5rem;
}
.text.font-body-xlarge {
  font-size: 1.25rem;
}
.text.font-body-large {
  font-size: 1.125rem;
}
.text.font-body {
  font-size: 1rem;
}
.text.font-body-small {
  font-size: 0.875rem;
  font-weight: normal;
}
.text.font-body-xsmall {
  font-size: 0.75rem;
}
.text.font-body-xxsmall {
  font-size: 0.625rem;
}

.font-caption {
  font-size: 0.75rem;
}

.font-label {
  font-size: 0.875rem;
}

.font-question-label {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 4px;
}

.font-table-header {
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
}

.font-small-alert {
  font-size: 0.625rem;
  font-weight: 700;
}

.font-heading-1, h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

.font-heading-2, h2 {
  font-size: 2.25rem;
  font-weight: 700;
}

.font-heading-3, h3 {
  font-size: 2rem;
  font-weight: 700;
}

.font-heading-4, h4 {
  font-size: 1.75rem;
  font-weight: 700;
}

.font-heading-5, h5 {
  font-size: 1.5rem;
  font-weight: 700;
}

.font-heading-6, h6 {
  font-size: 1.25rem;
  font-weight: 700;
}

.font-heading-7, h7 {
  font-size: 1.125rem;
  font-weight: 700;
}

.font-heading-8, h8 {
  font-size: 1rem;
  font-weight: 700;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.animate {
  -webkit-transition: all 2s;
  /* Safari prior 6.1 */
  transition: all 0.2s ease-out;
}

.disable-selection {
  user-select: none;
}
.disable-selection * {
  user-select: none;
}

.masking-gradient-top {
  position: relative;
}
.masking-gradient-top:after {
  content: "";
  display: block;
  width: calc(100% - 15px);
  height: 40px;
  position: absolute;
  top: -42px;
  left: 0;
  background: white;
  background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0.7) 60%, rgba(255, 255, 255, 0.2) 100%);
  z-index: 100;
  pointer-events: none;
}

.readonly,
[readonly],
.disabled,
[disabled] {
  opacity: 0.3 !important;
  cursor: not-allowed !important;
}
.readonly *,
[readonly] *,
.disabled *,
[disabled] * {
  cursor: not-allowed !important;
}

.invisible {
  opacity: 0;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.select-container {
  position: relative;
  overflow: hidden;
}
.select-container select {
  position: relative;
  width: 100%;
  outline: none;
  display: inline-block;
  z-index: 1;
  border-radius: 0;
  padding: 10px 30px 10px 10px;
  border: none;
  box-shadow: none;
  background: 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-container i {
  position: absolute;
  right: 0;
  top: 10px;
  color: #FFFFFF;
  font-size: 20px;
}

#page.go-full-screen {
  padding: 16px !important;
  margin: 0 !important;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  background-color: #FAFAFA;
}
#page.go-full-screen page-content {
  padding: 16px !important;
  margin: 0 !important;
}
#page.go-full-screen .connect-banner {
  display: none;
}
#page.go-full-screen .inset-container {
  margin: 0 !important;
  width: 100% !important;
}
#page.go-full-screen .filter {
  margin-top: 0 !important;
  margin-bottom: 16px;
}
#page.go-full-screen #toolbar-container.inset-container {
  margin-bottom: 16px !important;
}

.color-picker {
  left: 0 !important;
  top: 100px !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.invisible-scroll::-webkit-scrollbar {
  display: none;
}

.separator {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #333333;
}

.flex-align-center {
  display: flex;
  gap: 0.75em;
  align-items: center;
}

.pull-right {
  margin-left: auto;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

body {
  font-family: "Inter", sans-serif;
}

.font-headline {
  font-size: 4em;
  font-weight: 900;
  margin-block: 0.375em;
  line-height: 78px;
}

.font-heading-1, h1 {
  color: #323343;
  font-size: 2.625em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-2, h2 {
  color: #323343;
  font-size: 1.875em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-3, h3 {
  color: #323343;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 32px;
}

.font-heading-4, h4 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
  margin-block: 0.75em;
}

.font-heading-5, h5 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-6, h6 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-7, h7 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-8, h8 {
  color: #323343;
  font-size: 1em;
  font-weight: 700;
}

.text-white {
  color: #FFFFFF;
}

.text-gray-1 {
  color: #F6F6F6;
}

.text-gray-3 {
  color: #D4D4D4;
}

.text-gray-4 {
  color: #C4C4C4;
}

.text-gray-5 {
  color: #959595;
}

.text-gray-6, .active-check-tiny-header, .process-caption, .list-head-title {
  color: #757575;
}

.text-gray-8 {
  color: #333333;
}

.text-connect-primary {
  color: #323343;
}

.text-connect-accent {
  color: #F1A948;
}

.text-positive {
  color: #15BB8F;
}

.text-invalid {
  color: #E05050;
}

.text-alert {
  color: #EFAF41;
}

.text-italic {
  color: #525252;
  font-style: italic;
}

.font-size-xs, .active-check-tiny-header, .process-caption {
  font-size: 0.75em;
}

.font-size-s, .list-head-title, .img-empty p {
  font-size: 0.875em;
}

.font-size-regular {
  font-size: 1em;
}

.font-size-l {
  font-size: 1.125em;
}

.font-size-xl {
  font-size: 1.5em;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold, .active-check-tiny-header, .img-empty p {
  font-weight: 700 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.icon-size-s {
  font-size: 14px;
}

.icon-size-m {
  font-size: 24px;
}

.icon-size-regular {
  font-size: 16px;
}

.icon-size-l {
  font-size: 32px;
}

.icon-size-xl {
  font-size: 48px;
}

.icon-size-xxl {
  font-size: 56px;
}

.icon-size-xxxl {
  font-size: 72px;
}

.icon-size-huge {
  font-size: 120px;
}

.clamp-overflow-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.btn {
  font-weight: 500;
  font-family: "Inter", sans-serif;
  text-align: center;
  vertical-align: middle;
  outline: none;
  display: flex;
  justify-content: center;
  place-items: center;
}
.btn * {
  color: inherit;
  display: inline-block;
}
.btn:not(:disabled) {
  cursor: pointer;
}
.btn.large, .btn.medium {
  border-radius: 40px;
}
.btn.large {
  font-size: 1em;
  line-height: 24px;
  padding: 12px 24px;
}
.btn.large.secondary {
  padding: 11px 23px;
}
.btn.large.icon {
  padding: 0;
  width: 48px;
  height: 48px;
}
.btn.medium {
  font-size: 0.875em;
  line-height: 22px;
  padding: 9.5px 16px 8.5px;
}
.btn.medium.icon {
  padding: 0;
  width: 40px;
  height: 40px;
}
.btn.medium.secondary {
  padding: 8.5px 15px 7.5px;
}
.btn.tab {
  font-size: 1em;
  position: relative;
  line-height: 68px;
  vertical-align: middle;
}
.btn.tab::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  transition: all 0.2s ease-in-out;
  height: 4px;
  border-radius: 2px;
}
.btn.tab.active {
  font-weight: 900;
}
.btn.tab:hover::after, .btn.tab.active::after {
  left: 0;
  width: 100%;
  background-color: #282936;
}
.btn.primary {
  border: 0;
}
.btn.primary.dark {
  color: white;
  background-color: #323343;
}
.btn.primary.dark:hover {
  background-color: #282936;
}
.btn.primary.light {
  color: #333333;
  background-color: #EAEBEC;
}
.btn.primary.light:hover {
  background-color: #D4D4D4;
}
.btn.primary.white {
  color: #333333;
  background-color: #FFFFFF;
}
.btn.primary.white:hover {
  background-color: #EAEBEC;
}
.btn.primary:disabled {
  color: #C4C4C4 !important;
  background-color: #EAEAEA !important;
}
.btn.secondary {
  color: #333333;
  background-color: #FFFFFF;
  border: 1px solid #333333;
}
.btn.secondary:hover {
  background-color: #EAEBEC;
}
.btn.secondary.white {
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  background-color: transparent;
}
.btn.secondary.white:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn.secondary:disabled {
  color: #C4C4C4 !important;
  border: 1px solid #C4C4C4;
}
.btn.positive {
  color: #15BB8F;
  border: 1px solid #15BB8F;
  background-color: transparent;
}
.btn.positive-fill {
  color: #FFFFFF;
  border: 1px solid #15BB8F;
  background-color: #15BB8F;
}
.btn.negative-fill {
  color: #FFFFFF;
  border: 1px solid #E05050;
  background-color: #E05050;
}
.btn.negative {
  color: #E05050;
  border: 1px solid #E05050;
  background-color: transparent;
}
.btn.alert {
  color: #FFFFFF;
  border: 1px solid #EFAF41;
  background-color: #EFAF41;
}
.btn.text {
  color: #323343;
  background-color: transparent;
  border: none;
}
.btn.text.white {
  color: #FFFFFF;
}
.btn.transparent {
  border: 1px solid #333333;
  background-color: transparent;
}
.btn.empty {
  border: 0;
  padding: 0;
  background-color: transparent;
}
.btn.primary.dark i, .btn.primary.dark mat-icon {
  color: #F1A948;
}
.btn.primary.icon-white i, .btn.primary.icon-white mat-icon {
  color: #FFFFFF;
}
.btn i, .btn mat-icon {
  text-align: center;
  align-self: center;
}
.btn:disabled i, .btn:disabled mat-icon {
  color: #C4C4C4 !important;
}
.btn.icon-l i, .btn.icon-l mat-icon {
  margin-right: 8px;
}
.btn.icon-r i, .btn.icon-r mat-icon {
  margin-left: 8px;
}
.btn.large i, .btn.text i {
  font-size: 16px;
}
.btn.large mat-icon, .btn.text mat-icon {
  font-size: 24px;
}
.btn.medium i {
  font-size: 14px;
}
.btn.medium mat-icon {
  font-size: 22px;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

body {
  font-family: "Inter", sans-serif;
}

.font-headline {
  font-size: 4em;
  font-weight: 900;
  margin-block: 0.375em;
  line-height: 78px;
}

.font-heading-1, h1 {
  color: #323343;
  font-size: 2.625em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-2, h2 {
  color: #323343;
  font-size: 1.875em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-3, h3 {
  color: #323343;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 32px;
}

.font-heading-4, h4 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
  margin-block: 0.75em;
}

.font-heading-5, h5 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-6, h6 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-7, h7 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-8, h8 {
  color: #323343;
  font-size: 1em;
  font-weight: 700;
}

.text-white {
  color: #FFFFFF;
}

.text-gray-1 {
  color: #F6F6F6;
}

.text-gray-3 {
  color: #D4D4D4;
}

.text-gray-4 {
  color: #C4C4C4;
}

.text-gray-5 {
  color: #959595;
}

.text-gray-6, .active-check-tiny-header, .process-caption, .list-head-title {
  color: #757575;
}

.text-gray-8 {
  color: #333333;
}

.text-connect-primary {
  color: #323343;
}

.text-connect-accent {
  color: #F1A948;
}

.text-positive {
  color: #15BB8F;
}

.text-invalid {
  color: #E05050;
}

.text-alert {
  color: #EFAF41;
}

.text-italic {
  color: #525252;
  font-style: italic;
}

.font-size-xs, .active-check-tiny-header, .process-caption {
  font-size: 0.75em;
}

.font-size-s, .list-head-title, .img-empty p {
  font-size: 0.875em;
}

.font-size-regular {
  font-size: 1em;
}

.font-size-l {
  font-size: 1.125em;
}

.font-size-xl {
  font-size: 1.5em;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold, .active-check-tiny-header, .img-empty p {
  font-weight: 700 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.icon-size-s {
  font-size: 14px;
}

.icon-size-m {
  font-size: 24px;
}

.icon-size-regular {
  font-size: 16px;
}

.icon-size-l {
  font-size: 32px;
}

.icon-size-xl {
  font-size: 48px;
}

.icon-size-xxl {
  font-size: 56px;
}

.icon-size-xxxl {
  font-size: 72px;
}

.icon-size-huge {
  font-size: 120px;
}

.clamp-overflow-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.mat-checkbox *:not(mat-icon, .fa),
.mat-card *:not(mat-icon, .fa),
.mat-button *:not(mat-icon, .fa),
.mat-icon-button *:not(mat-icon, .fa),
.mat-stroked-button *:not(mat-icon, .fa),
.mat-flat-button *:not(mat-icon, .fa),
.mat-fab *:not(mat-icon, .fa),
.mat-mini-fab *:not(mat-icon, .fa),
.mat-list-item *:not(mat-icon, .fa),
.mat-tab-group *:not(mat-icon, .fa) {
  font-family: "Inter", sans-serif;
}

.mat-icon {
  color: inherit;
  margin-top: 2px;
}

.mat-form-field {
  width: 100%;
}
.mat-form-field.no-padding .mat-form-field-wrapper {
  padding: 0 !important;
  margin: 0 !important;
}
.mat-form-field.tertiary .mat-form-field-infix {
  padding: 0px 0 6px 0;
  border-top: 11px solid transparent;
}
.mat-form-field.tertiary .mat-form-field-wrapper {
  padding: 0;
  margin: 0;
}
.mat-form-field.tertiary .mat-form-field-wrapper input {
  font-size: 14px;
  line-height: 16px;
}
.mat-form-field .mat-form-field-infix {
  padding: 15px 0 13px 0 !important;
  border-top: 0;
  width: auto !important;
}
.mat-form-field .mat-form-field-wrapper {
  font-size: 14px !important;
  line-height: 16px;
}
.mat-form-field .mat-form-field-wrapper .mat-error {
  font-size: 10px !important;
}
.mat-form-field:not(:disabled) .mat-form-field-outline .mat-form-field-outline-start {
  background-color: #FFFFFF;
}
.mat-form-field:not(:disabled) .mat-form-field-outline .mat-form-field-outline-gap {
  background-color: #FFFFFF;
}
.mat-form-field:not(:disabled) .mat-form-field-outline .mat-form-field-outline-end {
  background-color: #FFFFFF;
}
.mat-form-field.mat-form-field-disabled.mat-focused .mat-form-field-label {
  color: #B3ADB8;
}
.mat-form-field.mat-form-field-disabled:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap {
  border-top: 1px solid #B3ADB8;
}
.mat-form-field.mat-form-field-disabled .mat-form-field-outline .mat-form-field-outline-start {
  border-left: 1px solid #B3ADB8;
  border-top: 1px solid #B3ADB8;
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field.mat-form-field-disabled .mat-form-field-outline .mat-form-field-outline-gap {
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field.mat-form-field-disabled .mat-form-field-outline .mat-form-field-outline-end {
  border-right: 1px solid #B3ADB8;
  border-top: 1px solid #B3ADB8;
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field.mat-form-field-disabled mat-label {
  color: #B3ADB8;
}
.mat-form-field.light:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap {
  border-top: 1px solid #B3ADB8;
}
.mat-form-field.light .mat-form-field-outline .mat-form-field-outline-start {
  border-left: 1px solid #B3ADB8;
  border-top: 1px solid #B3ADB8;
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field.light .mat-form-field-outline .mat-form-field-outline-gap {
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field.light .mat-form-field-outline .mat-form-field-outline-end {
  border-right: 1px solid #B3ADB8;
  border-top: 1px solid #B3ADB8;
  border-bottom: 1px solid #B3ADB8;
}
.mat-form-field:not(.mat-form-field-disabled):not(.light).mat-focused .mat-form-field-outline .mat-form-field-outline-start, .mat-form-field:not(.mat-form-field-disabled):not(.light):hover .mat-form-field-outline .mat-form-field-outline-start {
  border-left-width: 2px !important;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}
.mat-form-field:not(.mat-form-field-disabled):not(.light).mat-focused .mat-form-field-outline .mat-form-field-outline-gap, .mat-form-field:not(.mat-form-field-disabled):not(.light):hover .mat-form-field-outline .mat-form-field-outline-gap {
  border-bottom-width: 2px !important;
}
.mat-form-field:not(.mat-form-field-disabled):not(.light).mat-focused .mat-form-field-outline .mat-form-field-outline-end, .mat-form-field:not(.mat-form-field-disabled):not(.light):hover .mat-form-field-outline .mat-form-field-outline-end {
  border-right-width: 2px !important;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}
.mat-form-field:not(.mat-form-field-disabled):not(.light).mat-focused:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap, .mat-form-field:not(.mat-form-field-disabled):not(.light):hover:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap {
  border-top-width: 2px !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #E05050 !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-start {
  border-left: 1px solid #E05050;
  border-top: 1px solid #E05050;
  border-bottom: 1px solid #E05050;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-gap {
  border-bottom: 1px solid #E05050;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-end {
  border-right: 1px solid #E05050;
  border-top: 1px solid #E05050;
  border-bottom: 1px solid #E05050;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-start {
  background-color: #FFFFFF;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-gap {
  background-color: #FFFFFF;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-end {
  background-color: #FFFFFF;
}
.mat-form-field.mat-form-field-invalid:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap {
  border-top: 1px solid #E05050;
}
.mat-form-field button {
  position: absolute;
  right: 0;
  top: 2px;
}

.mat-tab-group {
  border-radius: 6px;
  background-color: #FFFFFF;
  overflow: hidden;
}
.mat-tab-group .mat-tab-label {
  font-weight: 700;
  opacity: 1;
}
.mat-tab-group .mat-tab-label.mat-tab-disabled {
  color: #B3ADB8 !important;
}

.mat-checkbox .mat-checkbox-label {
  word-spacing: normal !important;
  flex: 1;
}
.mat-checkbox.padded .mat-checkbox-layout {
  flex: 1;
  padding: 16px 0;
}
.mat-checkbox.padded .mat-checkbox-inner-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0;
}
.mat-checkbox .mat-checkbox-inner-container, .mat-checkbox .mat-checkbox-frame {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}
.mat-checkbox .mat-checkbox-frame {
  border: 1px solid #333333;
}
.mat-checkbox .mat-checkbox-background {
  background-color: transparent !important;
  border-radius: 4px;
}
.mat-checkbox.mat-checkbox-checked .mat-checkbox-frame {
  border-color: #282936;
}
.mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: #282936 !important;
}
.mat-checkbox.mat-checkbox-checked .mat-checkbox-checkmark-path {
  stroke: #FFFFFF !important;
}
.mat-checkbox .mat-checkbox-checkmark {
  fill: #282936;
}
.mat-checkbox.mat-checkbox-disabled .mat-checkbox-frame {
  border-color: #D4D4D4 !important;
}
.mat-checkbox.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
  background-color: #D4D4D4 !important;
}
.mat-checkbox.mat-checkbox-disabled .mat-checkbox-label {
  color: #D4D4D4 !important;
}
.mat-checkbox.mat-checkbox-disabled .mat-checkbox-checkmark-path {
  stroke: #FFFFFF !important;
}
.mat-checkbox .mat-checkbox-label {
  font-size: 1em;
  line-height: 32px !important;
  vertical-align: middle;
  color: #333333;
}
.mat-checkbox.medium .mat-checkbox-inner-container, .mat-checkbox.medium .mat-checkbox-frame {
  width: 24px;
  height: 24px;
}
.mat-checkbox.medium .mat-checkbox-label {
  font-size: 0.875em;
  line-height: 24px !important;
}
.mat-checkbox.small .mat-checkbox-inner-container, .mat-checkbox.small .mat-checkbox-frame {
  width: 16px;
  height: 16px;
}
.mat-checkbox.small .mat-checkbox-label {
  font-size: 0.75em;
  line-height: 16px !important;
}
.mat-checkbox.small-container .mat-checkbox-inner-container, .mat-checkbox.small-container .mat-checkbox-frame {
  width: 16px;
  height: 16px;
}

.mat-datepicker-toggle button {
  padding: 0 !important;
}

.mat-form-field .mat-form-field-label,
.mat-form-field.mat-focused .mat-form-field-label,
.font-question-label {
  color: #757575 !important;
}
.mat-form-field .mat-form-field-label *,
.mat-form-field.mat-focused .mat-form-field-label *,
.font-question-label * {
  color: #757575 !important;
}

.mat-form-field:not(.mat-form-field-disabled):not(.mat-form-field-should-float):not(.light):not(.mat-form-field-invalid) .mat-form-field-outline .mat-form-field-outline-gap,
.mat-form-field:not(.mat-form-field-disabled):not(.mat-form-field-invalid):not(.light) .mat-form-field-outline .mat-form-field-outline-start,
.mat-form-field:not(.mat-form-field-disabled):not(.mat-form-field-invalid):not(.light) .mat-form-field-outline .mat-form-field-outline-gap,
.mat-form-field:not(.mat-form-field-disabled):not(.mat-form-field-invalid):not(.light) .mat-form-field-outline .mat-form-field-outline-end {
  border-color: #D4D4D4 !important;
}

.mat-slide-toggle span {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
}

.mat-slide-toggle.mat-checked.mat-slide-toggle-white.mat-primary .mat-slide-toggle-thumb {
  background-color: #FFFFFF !important;
}
.mat-slide-toggle.mat-checked.mat-slide-toggle-white.mat-primary .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38) !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #15BB8F !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(21, 187, 143, 0.38) !important;
}

.mat-slide-toggle.mat-slide-toggle-white .mat-slide-toggle-label {
  color: #FFFFFF;
}

.mat-slide-toggle.mat-slide-toggle-reverse .mat-slide-toggle-label {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.mat-progress-bar.green {
  height: 6px !important;
}
.mat-progress-bar.green .mat-progress-bar-fill::after {
  background-color: #15BB8F;
}
.mat-progress-bar.green .mat-progress-bar-buffer {
  background-color: #EAEBEC;
}

.cdk-overlay-pane {
  max-width: 100% !important;
}
@media screen and (max-width: 599px) {
  .cdk-overlay-pane {
    position: fixed;
    width: 100% !important;
    height: 100% !important;
  }
}

.mat-dialog-content {
  margin: 0 !important;
}

.mat-dialog-container {
  padding: 0 !important;
}

@media screen and (max-width: 599px) {
  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    transform: none !important;
    left: 16px !important;
    right: 16px !important;
    bottom: 0 !important;
    width: auto !important;
    height: auto !important;
    max-height: 80% !important;
  }
  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-select-panel {
    min-width: calc(100% - 30px) !important;
  }
}
.mat-paginator-sticky {
  bottom: 0px;
  position: sticky;
  z-index: 10;
}

.mat-select-arrow-wrapper {
  transform: none !important;
}

.mat-form-field-subscript-wrapper {
  margin-top: 0.4em !important;
}

.mat-tab-group {
  height: 100%;
}
.mat-tab-group .mat-tab-body-wrapper {
  height: 100%;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

body {
  font-family: "Inter", sans-serif;
}

.font-headline {
  font-size: 4em;
  font-weight: 900;
  margin-block: 0.375em;
  line-height: 78px;
}

.font-heading-1, h1 {
  color: #323343;
  font-size: 2.625em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-2, h2 {
  color: #323343;
  font-size: 1.875em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-3, h3 {
  color: #323343;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 32px;
}

.font-heading-4, h4 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
  margin-block: 0.75em;
}

.font-heading-5, h5 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-6, h6 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-7, h7 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-8, h8 {
  color: #323343;
  font-size: 1em;
  font-weight: 700;
}

.text-white {
  color: #FFFFFF;
}

.text-gray-1 {
  color: #F6F6F6;
}

.text-gray-3 {
  color: #D4D4D4;
}

.text-gray-4 {
  color: #C4C4C4;
}

.text-gray-5 {
  color: #959595;
}

.text-gray-6, .active-check-tiny-header, .process-caption, .list-head-title {
  color: #757575;
}

.text-gray-8 {
  color: #333333;
}

.text-connect-primary {
  color: #323343;
}

.text-connect-accent {
  color: #F1A948;
}

.text-positive {
  color: #15BB8F;
}

.text-invalid {
  color: #E05050;
}

.text-alert {
  color: #EFAF41;
}

.text-italic {
  color: #525252;
  font-style: italic;
}

.font-size-xs, .active-check-tiny-header, .process-caption {
  font-size: 0.75em;
}

.font-size-s, .list-head-title, .img-empty p {
  font-size: 0.875em;
}

.font-size-regular {
  font-size: 1em;
}

.font-size-l {
  font-size: 1.125em;
}

.font-size-xl {
  font-size: 1.5em;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold, .active-check-tiny-header, .img-empty p {
  font-weight: 700 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.icon-size-s {
  font-size: 14px;
}

.icon-size-m {
  font-size: 24px;
}

.icon-size-regular {
  font-size: 16px;
}

.icon-size-l {
  font-size: 32px;
}

.icon-size-xl {
  font-size: 48px;
}

.icon-size-xxl {
  font-size: 56px;
}

.icon-size-xxxl {
  font-size: 72px;
}

.icon-size-huge {
  font-size: 120px;
}

.clamp-overflow-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.mat-menu-panel {
  min-height: auto !important;
}
.mat-menu-panel .help-menu {
  border-radius: 10px;
  padding: 15px;
}
.mat-menu-panel .help-menu h4 {
  font-size: 18px;
  margin: 0 0 10px 0;
  padding-bottom: 10px;
}
.mat-menu-panel .help-menu span {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
}

.mat-menu-item {
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 0;
}

a.mat-menu-item:hover {
  text-decoration: none;
}

.mat-menu-panel.wider-mat-menu {
  max-width: 330px;
}

.mat-menu-content {
  padding: 0 !important;
}
.mat-menu-content button {
  border-radius: 0;
  padding: 0 20px;
  font-size: 16px;
}
.mat-menu-content button *:not(mat-icon):not(.fa) {
  font-size: 16px;
}

.cdk-overlay-container .cdk-global-overlay-wrapper {
  align-items: center !important;
  justify-content: center !important;
}

.mat-snack-bar-container.alert-info {
  background-color: #EAEBEC;
}
.mat-snack-bar-container.alert-warning {
  background-color: #EFAF41;
}
.mat-snack-bar-container.alert-success {
  background-color: #15BB8F;
}
.mat-snack-bar-container.alert-error {
  background-color: #E05050;
}
.mat-snack-bar-container .notification {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}
.mat-snack-bar-container .notification .content {
  max-width: 85%;
  margin-right: 30px;
}
.mat-snack-bar-container .notification .content label {
  font-size: 20px;
  font-weight: 600;
}
.mat-snack-bar-container .notification .content p {
  margin: 0;
}
.mat-snack-bar-container .notification .mat-icon.type {
  font-size: 40px;
  margin-right: 15px;
  height: 40px;
  width: 40px;
}
.mat-snack-bar-container .notification .mat-icon.close {
  position: absolute;
  right: 15px;
  top: 15px;
}
.mat-snack-bar-container .notification .mat-icon.close:hover {
  cursor: pointer;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

body {
  font-family: "Inter", sans-serif;
}

.font-headline {
  font-size: 4em;
  font-weight: 900;
  margin-block: 0.375em;
  line-height: 78px;
}

.font-heading-1, h1 {
  color: #323343;
  font-size: 2.625em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-2, h2 {
  color: #323343;
  font-size: 1.875em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-3, h3 {
  color: #323343;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 32px;
}

.font-heading-4, h4 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
  margin-block: 0.75em;
}

.font-heading-5, h5 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-6, h6 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-7, h7 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-8, h8 {
  color: #323343;
  font-size: 1em;
  font-weight: 700;
}

.text-white {
  color: #FFFFFF;
}

.text-gray-1 {
  color: #F6F6F6;
}

.text-gray-3 {
  color: #D4D4D4;
}

.text-gray-4 {
  color: #C4C4C4;
}

.text-gray-5 {
  color: #959595;
}

.text-gray-6, .active-check-tiny-header, .process-caption, .list-head-title {
  color: #757575;
}

.text-gray-8 {
  color: #333333;
}

.text-connect-primary {
  color: #323343;
}

.text-connect-accent {
  color: #F1A948;
}

.text-positive {
  color: #15BB8F;
}

.text-invalid {
  color: #E05050;
}

.text-alert {
  color: #EFAF41;
}

.text-italic {
  color: #525252;
  font-style: italic;
}

.font-size-xs, .active-check-tiny-header, .process-caption {
  font-size: 0.75em;
}

.font-size-s, .list-head-title, .img-empty p {
  font-size: 0.875em;
}

.font-size-regular {
  font-size: 1em;
}

.font-size-l {
  font-size: 1.125em;
}

.font-size-xl {
  font-size: 1.5em;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold, .active-check-tiny-header, .img-empty p {
  font-weight: 700 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.icon-size-s {
  font-size: 14px;
}

.icon-size-m {
  font-size: 24px;
}

.icon-size-regular {
  font-size: 16px;
}

.icon-size-l {
  font-size: 32px;
}

.icon-size-xl {
  font-size: 48px;
}

.icon-size-xxl {
  font-size: 56px;
}

.icon-size-xxxl {
  font-size: 72px;
}

.icon-size-huge {
  font-size: 120px;
}

.clamp-overflow-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.table-wrapper, .table-wrapper-compact {
  flex: 1 1 0.000000001px;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  background-color: #FFFFFF !important;
}
.table-wrapper *:not(.fa, mat-icon), .table-wrapper-compact *:not(.fa, mat-icon) {
  font-family: "Inter", sans-serif;
}
.table-wrapper .mat-table, .table-wrapper-compact .mat-table {
  border: none;
  background-color: #FFFFFF !important;
}
.table-wrapper .mat-table *:not(.fa, mat-icon), .table-wrapper-compact .mat-table *:not(.fa, mat-icon) {
  font-size: 14px !important;
}
.table-wrapper .mat-table .mat-header-row, .table-wrapper-compact .mat-table .mat-header-row {
  border: none;
  align-items: stretch;
  background-color: #FAFAFA !important;
  color: #959595;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 2;
}
.table-wrapper .mat-table .mat-header-row .mat-header-cell, .table-wrapper-compact .mat-table .mat-header-row .mat-header-cell {
  background-color: #FAFAFA !important;
  border-left: none;
  border-right: none;
  border-top: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
  min-height: 0;
  padding: 16px 4px;
}
.table-wrapper .mat-table .mat-header-row .mat-header-cell:hover, .table-wrapper-compact .mat-table .mat-header-row .mat-header-cell:hover {
  background-color: #FAFAFA !important;
}
.table-wrapper .mat-table .mat-row, .table-wrapper-compact .mat-table .mat-row {
  background-color: #FFFFFF;
  border: none !important;
  align-items: stretch;
}
.table-wrapper .mat-table .mat-row .mat-cell, .table-wrapper-compact .mat-table .mat-row .mat-cell {
  color: #333333;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #EAEBEC !important;
  padding: 24px 4px;
  background-color: inherit;
}
.table-wrapper .mat-table .mat-row .mat-cell:first-of-type, .table-wrapper-compact .mat-table .mat-row .mat-cell:first-of-type {
  padding: 24px 16px;
  border-left: 1px solid #EAEBEC !important;
}
.table-wrapper .mat-table .mat-row .mat-cell:last-of-type, .table-wrapper-compact .mat-table .mat-row .mat-cell:last-of-type {
  border-right: 1px solid #EAEBEC !important;
}
.table-wrapper .mat-table .mat-cell:first-of-type, .table-wrapper-compact .mat-table .mat-cell:first-of-type, .table-wrapper .mat-table .mat-header-cell:first-of-type, .table-wrapper-compact .mat-table .mat-header-cell:first-of-type, .table-wrapper .mat-table .mat-footer-cell:first-of-type, .table-wrapper-compact .mat-table .mat-footer-cell:first-of-type {
  padding: 24px 16px;
}
.table-wrapper .mat-table .mat-checkbox .mat-checkbox-inner-container, .table-wrapper-compact .mat-table .mat-checkbox .mat-checkbox-inner-container, .table-wrapper .mat-table .mat-checkbox .mat-checkbox-frame, .table-wrapper-compact .mat-table .mat-checkbox .mat-checkbox-frame {
  width: 16px !important;
  height: 16px !important;
}
.table-wrapper .mat-table .mat-cell, .table-wrapper-compact .mat-table .mat-cell {
  min-height: auto;
}
.table-wrapper .expandable-row, .table-wrapper-compact .expandable-row {
  height: auto;
  min-height: 0;
}
.table-wrapper .expandable-row-container, .table-wrapper-compact .expandable-row-container {
  overflow: hidden;
  display: block;
  width: 100%;
}
.table-wrapper .expandable-row-content, .table-wrapper-compact .expandable-row-content {
  padding: 10px;
}
.table-wrapper .expanded-row, .table-wrapper-compact .expanded-row {
  border-bottom: 0;
}

.table-wrapper-compact .mat-table *:not(.fa, mat-icon) {
  font-size: 12px !important;
}
.table-wrapper-compact .mat-header-row .mat-header-cell {
  padding: 16px !important;
}
.table-wrapper-compact .mat-row .mat-cell {
  padding: 12px 16px !important;
}
.table-wrapper-compact .mat-cell:first-of-type, .table-wrapper-compact mat-header-cell:first-of-type, .table-wrapper-compact mat-footer-cell:first-of-type {
  padding: 16px 16px !important;
}

.mat-paginator {
  background-color: #FAFAFA !important;
  font-size: 14px !important;
}
.mat-paginator * {
  font-size: 14px !important;
}
.mat-paginator mat-form-field .mat-form-field-infix {
  padding: 0.4375em 0 !important;
}
.mat-paginator .mat-paginator-page-size {
  margin-top: 9px;
}

.mat-tab-group .mat-tab-body {
  padding: 30px;
}
@media screen and (max-width: 959px) {
  .mat-tab-group .mat-tab-body {
    padding: 10px;
  }
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

.mat-tooltip {
  background-color: #EAEBEC !important;
  opacity: 1;
  font-size: 13px;
  padding: 25px 30px !important;
  max-width: 400px !important;
  box-shadow: 0 0 14px 5px rgba(0, 0, 0, 0.3);
  line-height: 20px;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

app-dialog {
  height: 100%;
}

app-dialog.dialog-large-header-title .dialog-header-title {
  font-size: 32px;
}

.dialog-header {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.dialog-container {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px;
  top: 0;
  left: 0;
}
.dialog-container h3.dialog-header-title {
  font-size: 18px;
  padding: 20px 0px 20px 20px;
}
.dialog-container .dialog-content {
  height: calc(100% - 160px);
  flex: 1 1;
  padding: 0 2px;
  overflow: auto;
  padding: 10px 20px 0px 20px;
}
.dialog-container .dialog-content [body] {
  min-height: 100%;
}
.dialog-container .dialog-close {
  margin-right: 5px;
}
.dialog-container [footer-actions] {
  background-color: #FAFAFA;
  padding: 24px 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
}
.dialog-container [footer-actions] > button {
  width: 100%;
}
@media screen and (min-width: 600px) {
  .dialog-container [footer-actions] > button {
    width: auto;
  }
}
.dialog-container [footer-actions].padding-top-8 {
  padding-top: 8px;
}
.dialog-container:not(.right) {
  overflow: hidden;
  border-radius: 15px;
}
.dialog-container:not(.right) .dialog-header {
  position: relative;
  background-color: transparent !important;
  padding: 16px 24px;
}
.dialog-container:not(.right) .dialog-header .dialog-header-title {
  color: #323343 !important;
  font-size: 24px;
  font-weight: 700;
  padding: 0;
  background-color: transparent !important;
  height: auto !important;
}
.dialog-container:not(.right) .dialog-header button.dialog-close {
  position: absolute;
  right: 30px;
  top: 30px;
  margin: 0;
  width: 30px;
  height: 30px;
  line-height: 20px;
}
.dialog-container:not(.right) .dialog-header button.dialog-close mat-icon {
  margin: 0;
}
.dialog-container:not(.right) .dialog-content {
  padding: 16px 32px !important;
}
.dialog-container:not(.right) [footer-actions] {
  padding: 24px 32px;
}
.dialog-container.right {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  z-index: 999;
  width: 1200px;
  max-width: 100vw;
  height: 100%;
}
.dialog-container.right .dialog-header {
  border-radius: 0;
  height: auto;
  padding: 30px;
  margin-bottom: 20px;
  background: none !important;
}
.dialog-container.right .dialog-header h3.dialog-header-title {
  font-size: 24px;
  font-weight: 700;
  padding: 0;
  color: #323343 !important;
}
.dialog-container.right .dialog-header .dialog-close {
  text-align: center;
  padding: 0 !important;
  width: 30px;
  height: 30px;
  line-height: 20px;
  margin: 0;
}
.dialog-container.right .dialog-header .dialog-close mat-icon {
  margin: 0;
}
.dialog-container.right .dialog-content {
  position: static;
  height: calc(100% - 271px);
  flex: none !important;
  border: 0;
  border-radius: 0;
  padding: 0 30px;
  overflow-y: scroll;
}
.dialog-container.right .dialog-content section {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.dialog-container.right .dialog-content section:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.dialog-container.right .dialog-content section h3 {
  margin-bottom: 20px;
}
.dialog-container.right .dialog-content .mat-form-field-date-picker button {
  position: relative;
}
.dialog-container.right .button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: transparent !important;
  padding: 30px;
}
.dialog-container.right .button-container [footer-actions] {
  padding: 0;
  flex-direction: column;
}

body .mat-dialog-container {
  border-radius: 0;
  box-shadow: none;
  background: none;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

progress {
  border-radius: 4px;
  height: 8px;
  width: 100%;
}

progress::-webkit-progress-bar {
  background-color: #EAEBEC;
  border-radius: 4px;
}

progress::-webkit-progress-value {
  border-radius: 4px;
}

progress::-webkit-progress-value {
  background-color: #323343;
}

progress[value~="100"]::-webkit-progress-value {
  background-color: #15BB8F;
}

.lock-switch {
  width: fit-content;
  cursor: pointer;
  margin-bottom: 16px;
}

.lock-switch-wrapper {
  display: inline-block;
  background-color: #F6F6F6;
  margin-right: 16px;
  border-radius: 40px;
}

.lock-wrapper {
  display: inline-block;
  border-radius: inherit;
}
.lock-wrapper i {
  padding: 18px;
  color: #D4D4D4;
}
.lock-wrapper.active {
  background-color: #323343;
}
.lock-wrapper.active i {
  color: #FFFFFF;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

.divider-lightblue {
  border-bottom: 1px solid #EAEBEC;
  margin: 16px 0;
}

.divider-lightblue-medium-margin {
  border-bottom: 1px solid #EAEBEC;
  margin: 32px 0;
}

.divider-lightblue-vertical {
  border-right: 1px solid #EAEBEC;
  margin: 0 16px;
}

.divider-lightblue-vertical-medium-margin {
  border-right: 1px solid #EAEBEC;
  margin: 0 32px;
}

.selfie-portrait {
  width: 100%;
  max-height: 400px;
  border: 0.5px solid rgba(29, 30, 35, 0.1);
  border-radius: 4px;
  object-fit: cover;
}

.document-img {
  max-height: 400px;
  border: 0.5px solid rgba(29, 30, 35, 0.1);
  border-radius: 4px;
}

.img-empty {
  height: 275px;
  display: flex;
  background-color: #F6F6F6;
  border: 0.5px solid rgba(29, 30, 35, 0.1);
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  color: #C4C4C4;
  align-items: center;
  gap: 16px;
}
.img-empty i {
  font-size: 128px;
}
.list-wrapper {
  margin: 24px 0;
}

.list-head {
  border-top: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
}
@media screen and (min-width: 400px) and (max-width: 599px) {
  .list-head {
    display: none;
  }
}

.list-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media screen and (min-width: 600px) {
  .list-body {
    margin-top: 32px;
  }
}

.list-item, .list-item-white, .list-item-transparent {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  gap: 16px;
}
@media screen and (min-width: 600px) {
  .list-item, .list-item-white, .list-item-transparent {
    flex-direction: row;
  }
}

.list-item-transparent {
  border: 1px solid #EAEAEA;
  background-color: transparent;
}

.list-item-white {
  border: 1px solid #EAEBEC;
  background-color: #FFFFFF;
}

.process-card {
  border-radius: 8px;
  border: 1px solid #EAEAEA;
  background-color: #FFFFFF;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
}

.process-card-head {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding: 24px;
  background-color: #EAEBEC;
}

.process-head-collapsed {
  border-radius: inherit;
}

.process-card-body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.process-card-navigation {
  padding: 24px;
  display: flex;
  place-content: center flex-start;
  align-items: center;
  gap: 32px;
}
.process-card-navigation > div {
  flex: 1;
}

.process-card-footer {
  padding: 24px;
}

.process-caption {
  margin-bottom: 8px;
}

.background-lightblue {
  background-color: #EAEBEC;
}

.background-connect-accent {
  background-color: #F1A948;
}

.background-positive {
  background-color: #15BB8F;
}

.background-gray-0 {
  background-color: #FAFAFA;
}

.background-gray-1 {
  background-color: #F6F6F6;
}

.background-gray-2 {
  background-color: #EAEAEA;
}

.background-gray-3 {
  background-color: #D4D4D4;
}

.background-gray-4 {
  background-color: #C4C4C4;
}

.background-gray-5 {
  background-color: #959595;
}

.background-gray-6 {
  background-color: #757575;
}

.background-gray-7 {
  background-color: #525252;
}

.background-gray-8 {
  background-color: #333333;
}

.background-white {
  background-color: #FFFFFF;
}

.drop-shadow-low {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.drop-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.drop-shadow-high {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
}

.rag-red-text {
  color: #E05050 !important;
}

.rag-red-light-text {
  color: #FCEDED !important;
}

.rag-red-bg {
  background-color: #E05050 !important;
}

.rag-red-light-bg {
  background-color: #FCEDED !important;
}

.rag-amber-text {
  color: #EFAF41 !important;
}

.rag-amber-light-text {
  color: #FDF7EC !important;
}

.rag-amber-bg {
  background-color: #EFAF41 !important;
}

.rag-amber-light-bg {
  background-color: #FDF7EC !important;
}

.rag-blue-text {
  color: #317BD5 !important;
}

.rag-blue-light-text {
  color: #DDEBF6 !important;
}

.rag-blue-bg {
  background-color: #317BD5 !important;
}

.rag-blue-light-bg {
  background-color: #DDEBF6 !important;
}

.rag-green-text {
  color: #15BB8F !important;
}

.rag-green-light-text {
  color: #E8F8F4 !important;
}

.rag-green-bg {
  background-color: #15BB8F !important;
}

.rag-green-light-bg {
  background-color: #E8F8F4 !important;
}

.grey-050-bg {
  background-color: #FAFAFA;
}

.grey-050-text {
  color: #FAFAFA;
}

.grey-100-bg {
  background-color: #E6E6E6;
}

.grey-100-text {
  color: #E6E6E6;
}

.grey-200-bg {
  background-color: #CCCDCD;
}

.grey-200-text {
  color: #CCCDCD;
}

.grey-300-bg {
  background-color: #B3B3B3;
}

.grey-300-text {
  color: #B3B3B3;
}

.grey-400-bg {
  background-color: #9A9A9A;
}

.grey-400-text {
  color: #9A9A9A;
}

.grey-500-bg {
  background-color: #818181;
}

.grey-500-text {
  color: #818181;
}

.grey-600-bg {
  background-color: #676868;
}

.grey-600-text {
  color: #676868;
}

.grey-700-bg {
  background-color: #4E4F4F;
}

.grey-700-text {
  color: #4E4F4F;
}

.grey-800-bg {
  background-color: #353535;
}

.grey-800-text {
  color: #353535;
}

.grey-900-bg {
  background-color: #1B1C1C;
}

.grey-900-text {
  color: #1B1C1C;
}

.white-bg {
  background-color: #FFFFFF;
}

.white-text {
  color: #FFFFFF;
}

.black-bg {
  background-color: #020303;
}

.black-text {
  color: #020303;
}

.shadow, .mat-tab-group {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.shadow-light {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rag-red-border {
  border-color: #E05050 !important;
}

.rag-amber-border {
  border-color: #EFAF41 !important;
}

.rag-blue-border {
  border-color: #317BD5 !important;
}

.rag-green-border {
  border-color: #15BB8F !important;
}

.white-border {
  border-color: #FFFFFF !important;
}

.dark-grey-text {
  color: #B3ADB8 !important;
}

.dark-grey-bg {
  background-color: #B3ADB8 !important;
}

.dark-grey-border {
  border-color: #B3ADB8 !important;
}

.medium-grey-text {
  color: #D1D2DB !important;
}

.medium-grey-bg {
  background-color: #D1D2DB !important;
}

.medium-grey-border {
  border-color: #D1D2DB !important;
}

.light-grey-text {
  color: #EAEBEC !important;
}

.light-grey-bg {
  background-color: #EAEBEC !important;
}

.light-grey-border {
  border-color: #EAEBEC !important;
}

.grey-250-text {
  color: #c4c4c4 !important;
}

.grey-350-bg {
  background-color: #AAAAAA;
}

.grey-350-text {
  color: #AAAAAA;
}

.grey-550-bg {
  background-color: #757575 !important;
}

.grey-550-text {
  color: #757575 !important;
}

.greyscale {
  filter: grayscale(100%);
}

.connect-status {
  display: inline-block !important;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  color: #FFFFFF;
}
.connect-status .status {
  color: #FFFFFF;
}
.connect-status.pending {
  background-color: #EFAF41;
}
.connect-status.approved {
  background-color: #15BB8F;
}
.connect-status.rejected {
  background-color: #E05050;
}
.connect-status.inactive {
  border-color: #B3ADB8;
  color: #B3ADB8;
  background-color: #EAEBEC;
}

body {
  font-family: "Inter", sans-serif;
}

.font-headline {
  font-size: 4em;
  font-weight: 900;
  margin-block: 0.375em;
  line-height: 78px;
}

.font-heading-1, h1 {
  color: #323343;
  font-size: 2.625em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-2, h2 {
  color: #323343;
  font-size: 1.875em;
  font-weight: 700;
  line-height: 52px;
}

.font-heading-3, h3 {
  color: #323343;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 32px;
}

.font-heading-4, h4 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
  margin-block: 0.75em;
}

.font-heading-5, h5 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-6, h6 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-7, h7 {
  color: #323343;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 25px;
}

.font-heading-8, h8 {
  color: #323343;
  font-size: 1em;
  font-weight: 700;
}

.text-white {
  color: #FFFFFF;
}

.text-gray-1 {
  color: #F6F6F6;
}

.text-gray-3 {
  color: #D4D4D4;
}

.text-gray-4 {
  color: #C4C4C4;
}

.text-gray-5 {
  color: #959595;
}

.text-gray-6, .active-check-tiny-header, .list-head-title, .process-caption {
  color: #757575;
}

.text-gray-8 {
  color: #333333;
}

.text-connect-primary {
  color: #323343;
}

.text-connect-accent {
  color: #F1A948;
}

.text-positive {
  color: #15BB8F;
}

.text-invalid {
  color: #E05050;
}

.text-alert {
  color: #EFAF41;
}

.text-italic {
  color: #525252;
  font-style: italic;
}

.font-size-xs, .active-check-tiny-header, .process-caption {
  font-size: 0.75em;
}

.font-size-s, .img-empty p, .list-head-title {
  font-size: 0.875em;
}

.font-size-regular {
  font-size: 1em;
}

.font-size-l {
  font-size: 1.125em;
}

.font-size-xl {
  font-size: 1.5em;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold, .active-check-tiny-header, .img-empty p {
  font-weight: 700 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.icon-size-s {
  font-size: 14px;
}

.icon-size-m {
  font-size: 24px;
}

.icon-size-regular {
  font-size: 16px;
}

.icon-size-l {
  font-size: 32px;
}

.icon-size-xl {
  font-size: 48px;
}

.icon-size-xxl {
  font-size: 56px;
}

.icon-size-xxxl {
  font-size: 72px;
}

.icon-size-huge {
  font-size: 120px;
}

.clamp-overflow-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.active-check-navigation-wrapper ul {
  list-style-type: none;
  padding-left: 0;
}
.active-check-navigation-wrapper a, .active-check-navigation-wrapper button {
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;
}
.active-check-navigation-wrapper a::after, .active-check-navigation-wrapper button::after {
  content: "";
  float: left;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  margin-top: 16px;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
}
.active-check-navigation-wrapper a:hover::after, .active-check-navigation-wrapper button:hover::after {
  visibility: visible;
  transform: scaleX(1);
}
.active-check-navigation-wrapper button {
  padding: 0;
  border: 0;
  background-color: transparent;
  font-family: "Inter", sans-serif;
}
.active-check-navigation-wrapper a:not(:first-of-type), .active-check-navigation-wrapper button:not(:first-of-type) {
  padding: 0 10px;
}
.active-check-navigation-wrapper a.active::after, .active-check-navigation-wrapper a:hover::after, .active-check-navigation-wrapper button.active::after, .active-check-navigation-wrapper button:hover::after {
  transform: scaleX(1);
  visibility: visible;
  background-color: #323343;
}

.active-check-result-wrapper {
  padding: 8px 24px 24px 24px;
}

.active-check-tiny-header {
  margin-bottom: 8px;
}

.active-check-content-wrapper {
  padding: 24px;
}